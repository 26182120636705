import React from "react";
import { Link } from "gatsby"

const PrenotaOra = () => (
	<div className="prenota-ora">
		<Link className="nav-link" to="/inizia">
			Inizia ora
		</Link>
	</div>
);

export default PrenotaOra;
